/* Join1Percent.css - Updated to match buyhomelanding styling */

.join-1-percent {
  width: 100% !important;
  max-width: 100% !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  background-color: white !important;
  font-family: 'Inter', sans-serif !important;
  color: #333 !important;
  position: relative !important;
}

/* Hero Section Enhancements */
.join-1-percent .join-1-percent-hero {
  background: linear-gradient(135deg, #f8f9fa 0%, #e9f7fd 100%) !important;
  padding: 8rem 2rem !important;
  text-align: center !important;
  position: relative !important;
  overflow: hidden !important;
}

/* Add animated background elements */
.join-1-percent .join-1-percent-hero:before {
  content: "" !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239cdcf8' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
  opacity: 0.6 !important;
  animation: slideBg 20s linear infinite !important;
}

@keyframes slideBg {
  from { background-position: 0 0; }
  to { background-position: 100px 100px; }
}

.join-1-percent .join-1-percent-hero-content {
  position: relative !important;
  z-index: 2 !important;
}

/* 3D Text Effect */
.join-1-percent .join-1-percent-hero h1 {
  font-size: 6rem !important;
  font-weight: 900 !important;
  color: #333 !important;
  margin: 0 !important;
  letter-spacing: -2px !important;
  position: relative !important;
  display: inline-block !important;
  text-shadow: 3px 3px 0 rgba(156, 220, 248, 0.5), 
               6px 6px 0 rgba(156, 220, 248, 0.3),
               9px 9px 15px rgba(0, 0, 0, 0.1) !important;
  transition: transform 0.3s ease !important;
}

.join-1-percent .join-1-percent-hero h1:hover {
  transform: translateY(-5px) !important;
}

.join-1-percent .accent-text {
  color: #9CDCF8 !important;
  position: relative !important;
  z-index: 1 !important;
  background: linear-gradient(45deg, #9CDCF8, #7cb8d6) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  text-fill-color: transparent !important;
  display: inline-block !important;
}

.join-1-percent .hero-underline {
  width: 150px !important;
  height: 8px !important;
  background: linear-gradient(90deg, #9CDCF8, #7cb8d6) !important;
  margin: 1.5rem auto 0 !important;
  border-radius: 4px !important;
  position: relative !important;
  overflow: hidden !important;
}

.join-1-percent .hero-underline:after {
  content: '' !important;
  position: absolute !important;
  top: 0 !important;
  left: -100% !important;
  width: 100% !important;
  height: 100% !important;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent) !important;
  animation: shine 2s infinite !important;
}

@keyframes shine {
  to {
    left: 100% !important;
  }
}

.join-1-percent .join-1-percent-hero-content {
  position: relative !important;
  z-index: 2 !important;
}

.join-1-percent .join-1-percent-hero h1 {
  font-size: 5rem !important;
  font-weight: 800 !important;
  color: #333 !important;
  margin: 0 !important;
  letter-spacing: -1px !important;
  position: relative !important;
  display: inline-block !important;
}

.join-1-percent .accent-text {
  color: #9CDCF8 !important;
  position: relative !important;
  z-index: 1 !important;
}

.join-1-percent .hero-underline {
  width: 120px !important;
  height: 6px !important;
  background-color: #9CDCF8 !important;
  margin: 1rem auto 0 !important;
  border-radius: 3px !important;
}

/* Video Section */
.join-1-percent .join-1-percent-video-section {
  display: flex !important;
  flex-wrap: wrap !important;
  max-width: 1500px !important;
  margin: 3rem auto !important;
  background-color: #fff !important;
  border-radius: 15px !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05) !important;
  overflow: hidden !important;
  position: relative !important;
}

.join-1-percent .join-1-percent-left-column {
  flex: 1 !important;
  min-width: 300px !important;
  padding: 3.5rem !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: left !important;
  position: relative !important;
}

.join-1-percent .join-1-percent-right-column {
  flex: 1 !important;
  min-width: 300px !important;
  padding: 2rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.join-1-percent .primary-accent {
  color: #333333 !important;
  font-size: 3.8rem !important;
  font-weight: 900 !important;
  line-height: 1.2 !important;
  display: block !important;
  position: relative !important;
  margin-bottom: 0rem !important;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.05) !important;
  letter-spacing: -1px !important;
  background: linear-gradient(90deg, #333333 80%, #7cb8d6) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  transform: perspective(500px) rotateX(5deg) !important;
  transform-origin: center !important;
  transition: all 0.4s ease !important;
}

.join-1-percent .join-1-percent-left-column:hover .primary-accent {
  transform: perspective(500px) rotateX(0deg) !important;
}

.join-1-percent .secondary-accent {
  color: #9CDCF8 !important;
  font-size: 3.8rem !important;
  font-weight: 900 !important;
  line-height: 1.2 !important;
  background: linear-gradient(90deg, #9CDCF8, #7cb8d6) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  position: relative !important;
  display: inline-block !important;
  text-shadow: 3px 3px 0 rgba(156, 220, 248, 0.2) !important;
  letter-spacing: -1px !important;
  transition: all 0.4s ease !important;
}

.join-1-percent .join-1-percent-left-column:hover .secondary-accent {
  text-shadow: 4px 4px 0 rgba(156, 220, 248, 0.3) !important;
}





.join-1-percent .founder-message {
  margin-top: 2.5rem !important;
  position: relative !important;
  padding-left: 1.5rem !important;
  border-left: 4px solid #9CDCF8 !important;
}

.join-1-percent .founder-message p {
  font-size: 1.3rem !important;
  color: #555 !important;
  margin-bottom: 0.5rem !important;
  line-height: 1.6 !important;
  font-style: italic !important;
}

.join-1-percent .founder-name {
  font-weight: 600 !important;
  color: #333 !important;
  font-style: normal !important;
}

/* Video Container */
.join-1-percent .compact-video-container {
  position: relative !important;
  width: 100% !important;
  max-width: 600px !important;
  border-radius: 12px !important;
  overflow: hidden !important;
  background-color: #000 !important;
  aspect-ratio: 16 / 9 !important;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15) !important;
  transform: perspective(1000px) rotateY(-2deg) !important;
  transition: transform 0.5s ease !important;
}

.join-1-percent .compact-video-container:hover {
  transform: perspective(1000px) rotateY(0deg) !important;
}

.join-1-percent .video-play-button {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 70px !important;
  height: 70px !important;
  background-color: rgba(156, 220, 248, 0.9) !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  z-index: 10 !important;
  transition: all 0.3s ease !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2) !important;
}

.join-1-percent .video-play-button:hover {
  background-color: rgba(124, 184, 214, 0.9) !important;
  transform: translate(-50%, -50%) scale(1.1) !important;
}

.join-1-percent .video-play-icon {
  color: white !important;
  font-size: 1.8rem !important;
  margin-left: 6px !important;
}

.join-1-percent .compact-video-container video {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

/* Updated Why 1 Percent Lists Section */
.join-1-percent .join-1-percent-info-section {
  display: flex !important;
  flex-wrap: wrap !important;
  max-width: 1500px !important;
  margin: 4rem auto !important;
  background-color: #fff !important;
  border-radius: 15px !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05) !important;
  overflow: hidden !important;
  position: relative !important;
}

.join-1-percent .office-image-container {
  position: relative !important;
  width: 100% !important;
  height: 400px !important;
  overflow: hidden !important;
  border-radius: 12px !important;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
}

.join-1-percent .office-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  transition: transform 0.6s ease !important;
}

.join-1-percent .office-image-container:hover .office-image {
  transform: scale(1.03) !important;
}

.join-1-percent .image-overlay {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  background: linear-gradient(to top, rgba(0,0,0,0.8), transparent 100%) !important;
  padding: 3rem 2rem 2rem !important;
  color: white !important;
  transition: all 0.3s ease !important;
}

.join-1-percent .office-image-container:hover .image-overlay {
  opacity: 1 !important;
}

.join-1-percent .overlay-content h3 {
  font-size: 2rem !important;
  margin: 0 0 0.5rem !important;
  font-weight: 700 !important;
  text-shadow: 0 2px 4px rgba(0,0,0,0.3) !important;
}

.join-1-percent .overlay-content p {
  font-size: 1.2rem !important;
  margin: 0 !important;
  opacity: 0.9 !important;
  text-shadow: 0 1px 2px rgba(0,0,0,0.3) !important;
}

.join-1-percent .content-wrapper {
  padding: 2rem !important;
}

join-1-percent .why-heading {
  color: #333 !important;
  font-size: 2.8rem !important;
  margin: 0 0 1.5rem !important;
  position: relative !important;
  display: inline-block !important;
  line-height: 1.2 !important;
}

.join-1-percent .why-heading:after {
  content: "" !important;
  position: absolute !important;
  bottom: -10px !important;
  left: 0 !important;
  width: 80px !important;
  height: 5px !important;
  background-color: #9CDCF8 !important;
  border-radius: 3px !important;
}

.join-1-percent .model-subheading {
  color: #555 !important;
  font-size: 1.6rem !important;
  margin: 2rem 0 1.5rem !important;
  font-weight: 600 !important;
  line-height: 1.4 !important;
}

.join-1-percent .feature-block {
  margin-bottom: 3rem !important;
  padding: 2rem !important;
  background-color: #f9fafc !important;
  border-radius: 12px !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.03) !important;
  border-left: 4px solid #9CDCF8 !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}


.join-1-percent .feature-block h4 {
  color: #333 !important;
  font-size: 1.4rem !important;
  margin: 0 0 1rem !important;
  padding-bottom: 0.5rem !important;
  border-bottom: 1px solid rgba(156, 220, 248, 0.3) !important;
}

.join-1-percent .feature-block p {
  color: #555 !important;
  font-size: 1.1rem !important;
  line-height: 1.6 !important;
  margin-bottom: 1.5rem !important;
}

.join-1-percent .benefit-list {
  list-style-type: none !important;
  padding: 0 !important;
  margin: 0 0 1.5rem !important;
}

.join-1-percent .benefit-list li {
  display: flex !important;
  margin-bottom: 1rem !important;
  align-items: flex-start !important;
}

.join-1-percent .bullet-point {
  width: 12px !important;
  height: 12px !important;
  background-color: #9CDCF8 !important;
  border-radius: 50% !important;
  margin-top: 6px !important;
  margin-right: 12px !important;
  flex-shrink: 0 !important;
}

.join-1-percent .list-content {
  color: #555 !important;
  font-size: 1.1rem !important;
  line-height: 1.5 !important;
}

.join-1-percent .highlight-text {
  font-style: italic !important;
  font-weight: 600 !important;
  color: #333 !important;
  font-size: 1.1rem !important;
  padding: 1rem !important;
  background-color: rgba(156, 220, 248, 0.15) !important;
  border-radius: 6px !important;
}

/* Join Button */
.join-1-percent .join-button {
  background-color: #9CDCF8 !important;
  color: #000 !important;
  padding: 14px 28px !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  margin-top: 2rem !important;
  box-shadow: 0 8px 20px rgba(156, 220, 248, 0.3) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.join-1-percent .join-button:hover {
  background-color: #7cb8d6 !important;
  transform: translateY(-2px) !important;
}

.join-1-percent .button-icon {
  margin-left: 10px !important;
  transition: transform 0.3s ease !important;
}

.join-1-percent .join-button:hover .button-icon {
  transform: translateX(5px) !important;
}

.join-1-percent .pulse-animation {
  animation: pulse 2s infinite !important;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(156, 220, 248, 0.7) !important;
  }
  70% {
    box-shadow: 0 0 0 15px rgba(156, 220, 248, 0) !important;
  }
  100% {
    box-shadow: 0 0 0 0 rgba(156, 220, 248, 0) !important;
  }
}

/* Help Section */
.join-1-percent .join-1-percent-help-section {
  max-width: 1500px !important;
  margin: 4rem auto !important;
  position: relative !important;
  overflow: hidden !important;
}

.join-1-percent .help-section-inner {
  background-color: #9CDCF8;
  border-radius: 15px !important;
  padding: 3.5rem !important;
  color: black !important;
  box-shadow: 0 15px 35px rgba(156, 220, 248, 0.3) !important;
  position: relative !important;
  z-index: 1 !important;
}

.join-1-percent .join-1-percent-help-section h2 {
  color: black !important;
  font-size: 2.8rem !important;
  text-align: center !important;
  margin-bottom: 3rem !important;
  font-weight: 800 !important;
  position: relative !important;
  display: inline-block !important;
  padding-bottom: 0.5rem !important;
}



.join-1-percent .help-benefits-list {
  list-style-type: none !important;
  padding: 0 !important;
  
  margin:auto;
  display:flex;
}

.join-1-percent .benefit-item {
  display: block!important;
  align-items: flex-start !important;
  margin-bottom: 2rem !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  padding: 1.5rem !important;
  margin:20px;
  width:33%;
  border-radius: 10px !important;
  transition: transform 0.3s ease, background-color 0.3s ease !important;
}

.join-1-percent .benefit-item:hover {
  transform: translateY(-5px) !important;
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.join-1-percent .benefit-icon-container {
  background-color: black !important;
  color: #9CDCF8 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin:auto;
  margin-bottom:20px;
  flex-shrink: 0 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.join-1-percent .benefit-icon {
  font-size: 1.2rem !important;
}

.join-1-percent .benefit-content {
  flex: 1 !important;
  font-size: 1.2rem !important;
  line-height: 1.5 !important;
  color: black !important;
}

.join-1-percent .benefit-content strong {
  color: black !important;
  font-weight: 600 !important;
  display: block !important;
  margin-bottom: 0.5rem !important;
  font-size:1.3rem;
}

.join-1-percent .bottom-line-container {
  text-align: center !important;
  padding-top: 2rem !important;
  border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.join-1-percent .bottom-line {
  font-size: 1.8rem !important;
  font-weight: 700 !important;
  color: black !important;
  margin: 0 !important;
  position: relative !important;
  display: inline-block !important;
}

/* Smart Agents Section */
.join-1-percent .join-1-percent-smart-agents-section {
  max-width: 1500px !important;
  margin: 4rem auto !important;
  background-color: #fff !important;
  border-radius: 15px !important;
  padding: 3rem !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05) !important;
}

.join-1-percent .join-1-percent-smart-agents-section h2 {
  color: #333 !important;
  font-size: 2.5rem !important;
  text-align: center !important;
  margin-bottom: 3rem !important;
  position: relative !important;
}

.join-1-percent .join-1-percent-smart-agents-section h2:after {
  content: "" !important;
  position: absolute !important;
  bottom: -15px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: 80px !important;
  height: 4px !important;
  background-color: #9CDCF8 !important;
  border-radius: 2px !important;
}

.join-1-percent .smart-agents-grid {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 2rem !important;
  margin-bottom: 3rem !important;
}

.join-1-percent .smart-agents-item {
  flex: 1 !important;
  min-width: 300px !important;
  background-color: #f9fafc !important;
  border-radius: 12px !important;
  padding: 2rem !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.03) !important;
  display: flex !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

.join-1-percent .smart-agents-item:hover {
  transform: translateY(-5px) !important;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
}

.join-1-percent .checkmark-container {
  margin-right: 1.2rem !important;
  flex-shrink: 0 !important;
}

.join-1-percent .checkmark-icon {
  color: #9CDCF8 !important;
  font-size: 2rem !important;
}

.join-1-percent .smart-agents-content h3 {
  color: #333 !important;
  font-size: 1.5rem !important;
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
}

.join-1-percent .smart-agents-content p {
  color: #555 !important;
  font-size: 1.1rem !important;
  line-height: 1.6 !important;
  margin: 0 !important;
}

.join-1-percent .tools-success-container {
  background: linear-gradient(135deg, #f0f8ff 0%, #e1f0fc 100%) !important;
  border-radius: 12px !important;
  padding: 2.5rem !important;
  box-shadow: 0 8px 20px rgba(156, 220, 248, 0.2) !important;
}

.join-1-percent .tools-success-header {
  
  align-items: center !important;
  
  margin-bottom: 2rem !important;
}

.join-1-percent .tools-success-header h3 {
  color: #333 !important;
  font-size: 2rem !important;
  margin: 0 !important;
}

.join-1-percent .tools-list {
  list-style-type: none !important;
  display:flex;
  gap:20px;
  padding: 0 !important;
  margin: 0 !important;
}

.join-1-percent .tools-list li {
  display: flex !important;
  align-items: flex-start !important;
  
  background-color: rgba(255, 255, 255, 0.7) !important;
  padding: 1.2rem !important;
  border-radius: 8px !important;
  transition: transform 0.2s ease !important;
}

.join-1-percent .tools-list li:last-child {
  margin-bottom: 0 !important;
}

.join-1-percent .tools-list li:hover {
  transform: translateX(5px) !important;
}

.join-1-percent .tool-icon-container {
  background-color: #9CDCF8 !important;
  color: white !important;
  width: 36px !important;
  height: 36px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 1rem !important;
  flex-shrink: 0 !important;
}

.join-1-percent .tool-icon {
  font-size: 1rem !important;
}

.join-1-percent .tools-list li span {
  color: #333 !important;
  font-size: 1.1rem !important;
  line-height: 1.5 !important;
}

.join-1-percent .tools-list li strong {
  display: block !important;
  margin-bottom: 0.3rem !important;
  color: black!important;
}

/* Contact Section */
.join-1-percent .join-1-percent-contact-section {
  max-width: 1500px !important;
  margin: 4rem auto !important;
  padding: 0 20px !important;
}

.join-1-percent .join-1-percent-contact-section h2 {
  font-size:2.5em;
  padding-bottom:30px;
}

.join-1-percent .contact-grid {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  gap: 40px !important;
  background: transparent !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  padding: 0px !important;
}

.join-1-percent .contact-info-box {
  background: #f8f8f8 !important;
  padding: 30px !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

.join-1-percent .contact-info-box h2 {
  font-size: 34px !important;
  color: #333 !important;
  margin-bottom: 20px !important;
  padding-bottom:0px!important;
  font-weight: bold !important;
  font-family: 'Inter', sans-serif !important;
  text-align: left !important;
}

.join-1-percent .contact-divider {
  width: 50px !important;
  height: 3px !important;
  background-color: #9CDCF8 !important;
  margin-bottom: 20px !important;
}

.join-1-percent .contact-item {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 20px !important;
  color: #333 !important;
  background-color: white !important;
  padding: 35px !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05) !important;
  text-decoration: none !important;
  font-size: 18px !important;
  font-family: 'Inter' !important;
  transition: all 0.3s ease !important;
  border-radius: 5px !important;
}

.join-1-percent .contact-item:hover {
  color: #7cb8d6 !important;
  transform: translateY(-2px) !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
}

.join-1-percent .contact-icon {
  margin-right: 20px !important;
  color: #9CDCF8 !important;
  padding: 10px !important;
  background-color: #ECF5F9 !important;
  border-radius: 5px !important;
  width: 55px !important;
  height: 55px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.join-1-percent .contact-form-container {
  background: #f8f8f8 !important;
  padding: 30px !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

.join-1-percent .contact-form-container h2 {
  font-size: 34px !important;
  color: #333 !important;
  margin-bottom: 20px !important;
  padding-bottom:0px;
  font-weight: bold !important;
  font-family: 'Inter', sans-serif !important;
  text-align: left !important;
}

.join-1-percent .contact-form {
  width: 100% !important;
}

.join-1-percent .contact-form h3 {
  font-family: 'Inter', sans-serif !important;
  margin-bottom: 1rem !important;
}

.join-1-percent .contact-form form {
  display: flex !important;
  flex-direction: column !important;
}

.join-1-percent .contact-form input[type='text'],
.join-1-percent .contact-form input[type='email'],
.join-1-percent .contact-form input[type='tel'],
.join-1-percent .contact-form textarea {
  margin-bottom: 1rem !important;
  padding: 1rem !important;
  border: 1px solid #c9c9c9 !important;
  border-radius: 5px !important;
  width: 100% !important;
  font-family: 'Inter' !important;
  font-size: 15px !important;
  background-color: #fff !important;
}

.join-1-percent .name-fields,
.join-1-percent .contact-fields {
  display: flex !important;
  gap: 10px !important;
}

.join-1-percent .name-fields input[type="text"],
.join-1-percent .contact-fields input[type="email"],
.join-1-percent .contact-fields input[type="tel"] {
  width: 50% !important;
}

.join-1-percent .contact-form button,
.join-1-percent .submit-button {
  padding: 15px !important;
  border: none !important;
  border-radius: 5px !important;
  background-color: #9CDCF8 !important;
  color: black !important;
  cursor: pointer !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  transition: background-color 0.3s ease !important;
}

.join-1-percent .contact-form button:hover {
  background-color: #7cb8d6 !important;
}

.join-1-percent .checkbox-container {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 20px !important;
}

.join-1-percent .checkbox-container input[type='checkbox'] {
  margin: 0 10px 0 0 !important;
  width: auto !important;
  height: 16px !important;
  cursor: pointer !important;
}

.join-1-percent .checkbox-container label {
  font-size: 14px !important;
  color: #333 !important;
  cursor: pointer !important;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .join-1-percent .join-1-percent-hero h1 {
    font-size: 4rem !important;
  }
  
  .join-1-percent .primary-accent {
    font-size: 2.5rem !important;
  }
  
  .join-1-percent .join-1-percent-left-column,
  .join-1-percent .join-1-percent-right-column {
    padding: 2.5rem !important;
  }
  
  .join-1-percent .why-heading {
    font-size: 2.5rem !important;
  }
  
  .join-1-percent .model-subheading {
    font-size: 1.6rem !important;
  }
  
  .join-1-percent .compact-video-container {
    max-width: 500px !important;
  }
  
  .join-1-percent .join-1-percent-help-section h2 {
    font-size: 2.5rem !important;
  }
  
  .join-1-percent .bottom-line {
    font-size: 1.6rem !important;
  }

  .join-1-percent .join-1-percent-smart-agents-section h2,
  .join-1-percent .join-1-percent-contact-section h2 {
    font-size: 2.2rem !important;
  }
  
  .join-1-percent .tools-success-header h3 {
    font-size: 1.6rem !important;
  }
  
  .join-1-percent .checkmark-icon {
    font-size: 1.8rem !important;
  }
  
  .join-1-percent .smart-agents-content h3 {
    font-size: 1.4rem !important;
  }
}

@media (max-width: 992px) {
  .join-1-percent .join-1-percent-video-section,
  .join-1-percent .join-1-percent-info-section {
    flex-direction: column !important;
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  
  .join-1-percent .join-1-percent-hero h1 {
    font-size: 3.5rem !important;
  }
  
  .join-1-percent .primary-accent {
    font-size: 2.2rem !important;
    display: inline !important;
  }
  
  .join-1-percent .join-1-percent-right-column {
    order: -1 !important;
  }
  
  .join-1-percent .compact-video-container {
    max-width: 100% !important;
    margin-bottom: 2rem !important;
    transform: perspective(1000px) rotateY(0deg) !important;
  }
  
  .join-1-percent .office-image-container {
    max-height: 400px !important;
  }
  
  .join-1-percent .why-heading {
    font-size: 2.2rem !important;
    margin-top: 2rem !important;
  }
  
  .join-1-percent .model-subheading {
    font-size: 1.5rem !important;
  }
  
  .join-1-percent .feature-block h4 {
    font-size: 1.3rem !important;
  }
  
  .join-1-percent .join-1-percent-help-section {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  
  .join-1-percent .help-section-inner {
    padding: 2.5rem !important;
  }
  
  .join-1-percent .join-1-percent-help-section h2 {
    font-size: 2.2rem !important;
  }
  
  .join-1-percent .benefit-content {
    font-size: 1.1rem !important;
  }
  
  .join-1-percent .bottom-line {
    font-size: 1.5rem !important;
  }

  .join-1-percent .join-1-percent-smart-agents-section,
  .join-1-percent .join-1-percent-contact-section {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
    padding: 2.5rem !important;
  }
  
  .join-1-percent .smart-agents-grid {
    flex-direction: column !important;
  }
  
  .join-1-percent .smart-agents-item {
    width: 100% !important;
  }
  
  .join-1-percent .contact-form-container,
  .join-1-percent .contact-info-box {
    min-width: 100% !important;
  }
  
  .join-1-percent .tools-success-container {
    padding: 2rem !important;
  }
}

@media (max-width: 768px) {
  .join-1-percent .join-1-percent-hero {
    padding: 4rem 1.5rem !important;
  }
  
  .join-1-percent .join-1-percent-hero h1 {
    font-size: 3rem !important;
  }
  
  .join-1-percent .hero-underline {
    width: 100px !important;
    height: 5px !important;
  }
  
  .join-1-percent .join-1-percent-left-column,
  .join-1-percent .join-1-percent-right-column {
    padding: 2rem !important;
  }
  
  .join-1-percent .primary-accent {
    font-size: 2rem !important;
    text-align: center !important;
  }
  
  .join-1-percent .founder-message {
    padding-left: 1rem !important;
    border-left: 3px solid #9CDCF8 !important;
    margin-top: 2rem !important;
  }
  
  .join-1-percent .founder-message p {
    font-size: 1.1rem !important;
  }
  
  .join-1-percent .video-play-button {
    width: 60px !important;
    height: 60px !important;
  }
  
  .join-1-percent .video-play-icon {
    font-size: 1.5rem !important;
  }
  
  .join-1-percent .why-heading {
    font-size: 2rem !important;
    text-align: center !important;
  }
  
  .join-1-percent .why-heading:after {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  
  .join-1-percent .model-subheading {
    font-size: 1.4rem !important;
    text-align: center !important;
  }
  
  .join-1-percent .feature-block {
    padding: 1.2rem !important;
  }
  
  .join-1-percent .feature-block h4 {
    font-size: 1.2rem !important;
    text-align: center !important;
  }
  
  .join-1-percent .feature-block p {
    font-size: 1rem !important;
  }
  
  .join-1-percent .list-content {
    font-size: 1rem !important;
  }
  
  .join-1-percent .highlight-text {
    font-size: 1rem !important;
    text-align: center !important;
  }
  
  .join-1-percent .join-button {
    font-size: 1.1rem !important;
    padding: 0.9rem 2rem !important;
    width: 100% !important;
  }
  
  .join-1-percent .help-section-inner {
    padding: 2rem !important;
  }
  
  .join-1-percent .join-1-percent-help-section h2 {
    font-size: 2rem !important;
  }
  
  .join-1-percent .benefit-item {
    padding: 1.2rem !important;
  }
  
  .join-1-percent .benefit-icon-container {
    width: 35px !important;
    height: 35px !important;
  }
  
  .join-1-percent .benefit-content {
    font-size: 1rem !important;
  }
  
  .join-1-percent .bottom-line {
    font-size: 1.3rem !important;
  }

  .join-1-percent .join-1-percent-smart-agents-section h2,
  .join-1-percent .join-1-percent-contact-section h2 {
    font-size: 2rem !important;
  }
  
  .join-1-percent .tools-success-header {
    flex-direction: column !important;
    align-items: flex-start !important;
    text-align: center !important;
  }
  
  .join-1-percent .tools-success-header .checkmark-container {
    margin-bottom: 1rem !important;
    margin-right: 0 !important;
    align-self: center !important;
  }
  
  .join-1-percent .tools-success-header h3 {
    font-size: 1.5rem !important;
    text-align: center !important;
    width: 100% !important;
  }
  
  .join-1-percent .contact-info-box {
    padding: 2rem !important;
  }
  
  .join-1-percent .contact-form-container {
    padding: 2rem !important;
  }
}

@media (max-width: 480px) {
  .join-1-percent .join-1-percent-hero h1 {
    font-size: 2.5rem !important;
  }
  
  .join-1-percent .hero-underline {
    width: 80px !important;
    height: 4px !important;
  }
  
  .join-1-percent .join-1-percent-left-column,
  .join-1-percent .join-1-percent-right-column {
    padding: 1.5rem !important;
  }
  
  .join-1-percent .primary-accent {
    font-size: 1.8rem !important;
  }
  
  .join-1-percent .founder-message {
    padding-left: 0.8rem !important;
    border-left: 2px solid #9CDCF8 !important;
  }
  
  .join-1-percent .founder-message p {
    font-size: 1rem !important;
  }
  
  .join-1-percent .video-play-button {
    width: 50px !important;
    height: 50px !important;
  }
  
  .join-1-percent .video-play-icon {
    font-size: 1.2rem !important;
    margin-left: 3px !important;
  }
  
  .join-1-percent .why-heading {
    font-size: 1.8rem !important;
  }
  
  .join-1-percent .model-subheading {
    font-size: 1.3rem !important;
  }
  
  .join-1-percent .feature-block {
    padding: 1rem !important;
  }
  
  .join-1-percent .feature-block h4 {
    font-size: 1.1rem !important;
  }
  
  .join-1-percent .help-section-inner {
    padding: 1.5rem !important;
  }
  
  .join-1-percent .join-1-percent-help-section h2 {
    font-size: 1.7rem !important;
    margin-bottom: 2rem !important;
  }
  
  .join-1-percent .join-1-percent-help-section h2:after {
    height: 2px !important;
  }
  
  .join-1-percent .benefit-item {
    padding: 1rem !important;
  }
  
  .join-1-percent .benefit-icon-container {
    width: 30px !important;
    height: 30px !important;
    margin-right: 0.8rem !important;
  }
  
  .join-1-percent .benefit-icon {
    font-size: 0.9rem !important;
  }
  
  .join-1-percent .benefit-content {
    font-size: 0.9rem !important;
  }
  
  .join-1-percent .bottom-line {
    font-size: 1.2rem !important;
  }
  
  .join-1-percent .bottom-line:before, 
  .join-1-percent .bottom-line:after {
    font-size: 1.8rem !important;
  }

  .join-1-percent .join-1-percent-smart-agents-section,
  .join-1-percent .join-1-percent-contact-section {
    padding: 1.5rem !important;
  }
  
  .join-1-percent .join-1-percent-smart-agents-section h2,
  .join-1-percent .join-1-percent-contact-section h2 {
    font-size: 1.7rem !important;
  }
  
  .join-1-percent .smart-agents-item {
    padding: 1.5rem !important;
  }
  
  .join-1-percent .smart-agents-content h3 {
    font-size: 1.3rem !important;
  }
  
  .join-1-percent .tools-success-container {
    padding: 1.5rem !important;
  }
  
  .join-1-percent .tools-list li {
    padding: 1rem !important;
  }
  
  .join-1-percent .tool-icon-container {
    width: 30px !important;
    height: 30px !important;
  }
  
  .join-1-percent .tools-list li span {
    font-size: 1rem !important;
  }
  
  .join-1-percent .contact-info-box,
  .join-1-percent .contact-form-container {
    padding: 1.5rem !important;
  }
  
  .join-1-percent .contact-info-box h2,
  .join-1-percent .contact-form-container h2 {
    font-size: 1.7rem !important;
  }

  .join-1-percent .name-fields,
  .join-1-percent .contact-fields {
    flex-direction: column !important;
    gap: 1rem !important;
  }

  .join-1-percent .name-fields input[type="text"],
  .join-1-percent .contact-fields input[type="email"],
  .join-1-percent .contact-fields input[type="tel"] {
    width: 100% !important;
  }
}

.join-1-percent .join-1-percent-contact-section h2:after {
  content: "" !important;
    position: absolute !important;
    bottom: -15px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    width: 80px !important;
    height: 4px !important;
    background-color: #9CDCF8 !important;
    border-radius: 2px !important;
}

/* Updated styles for the tools section */

.join-1-percent .tools-success-container {
  background: linear-gradient(135deg, #f0f8ff 0%, #e1f0fc 100%) !important;
  border-radius: 12px !important;
  padding: 2.5rem !important;
  box-shadow: 0 8px 20px rgba(156, 220, 248, 0.2) !important;
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 2rem !important;
  align-items: center !important; /* Center align items vertically */
}

.join-1-percent .tools-success-content {
  flex: 1 !important;
  min-width: 300px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important; /* Center content vertically */
}

.join-1-percent .tools-success-header {
  align-items: flex-start !important;
  margin-bottom: 1.5rem !important;
}

.join-1-percent .tools-success-header h3 {
  color: #333 !important;
  font-size: 2.2rem !important;
  margin: 0 !important;
  text-align: left !important;
  position: relative !important;
  padding-bottom: 0.75rem !important;
}

.join-1-percent .tools-success-header h3:after {
  content: "" !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 60px !important;
  height: 3px !important;
  background-color: #9CDCF8 !important;
  border-radius: 2px !important;
}

.join-1-percent .tools-list {
  list-style-type: none !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 16px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.join-1-percent .tools-list li {
  display: flex !important;
  align-items: flex-start !important; /* Changed to flex-start to align items at the top */
  background-color: rgba(255, 255, 255, 0.85) !important;
  padding: 1.2rem 1.5rem !important;
  border-radius: 10px !important;
  transition: all 0.3s ease !important;
  border-left: 4px solid #9CDCF8 !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05) !important;
  text-align: left !important; /* Explicitly set text alignment */
}

.join-1-percent .tools-list li:last-child {
  margin-bottom: 0 !important;
}

.join-1-percent .tools-list li:hover {
  transform: translateX(5px) !important;
  background-color: rgba(255, 255, 255, 0.95) !important;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.08) !important;
}

.join-1-percent .tool-icon-container {
  background-color: #9CDCF8 !important;
  color: white !important;
  width: 42px !important;
  height: 42px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 1.2rem !important;
  margin-top: 0.2rem !important; /* Added to better align with text */
  flex-shrink: 0 !important;
  box-shadow: 0 4px 8px rgba(156, 220, 248, 0.3) !important;
}

.join-1-percent .tool-icon {
  font-size: 1.1rem !important;
}

.join-1-percent .tools-list li span {
  color: #333 !important;
  font-size: 1.1rem !important;
  line-height: 1.5 !important;
  display: block !important;
  text-align: left !important;
}

.join-1-percent .tools-list li strong {
  display: block !important;
  margin-bottom: 0.4rem !important;
  color: #111 !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  text-align: left !important;
}

/* Team Image Styling */
.join-1-percent .team-image-container {
  flex: 1 !important;
  min-width: 300px !important;
  max-width: 500px !important;
  min-height: 450px !important; /* Increased minimum height */
  border-radius: 15px !important;
  overflow: hidden !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1) !important;
  position: relative !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

.join-1-percent .team-image-container:hover {
  transform: translateY(-5px) !important;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15) !important;
}

.join-1-percent .team-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  object-position: center !important; /* Center the image */
  position: absolute !important; /* Position absolutely to fill container */
  top: 0 !important;
  left: 0 !important;
  transition: transform 0.6s ease !important;
}

.join-1-percent .team-image-container:hover .team-image {
  transform: scale(1.02) !important;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .join-1-percent .tools-success-container {
    padding: 2rem !important;
    flex-direction: column !important;
  }
  
  .join-1-percent .team-image-container {
    max-width: 100% !important;
    margin-top: 2rem !important;
    min-height: 400px !important; /* Adjusted for mobile */
  }
}

@media (max-width: 768px) {
  .join-1-percent .team-image-container {
    margin: 2rem auto 1rem !important;
    min-height: 350px !important;
  }
  
  .join-1-percent .tools-success-header h3 {
    text-align: center !important;
  }
  
  .join-1-percent .tools-success-header h3:after {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

@media (max-width: 480px) {
  .join-1-percent .team-image-container {
    min-height: 300px !important;
  }
}

/* Updated Tools Section Styles */
.join-1-percent .tools-success-container {
  background: linear-gradient(135deg, #f0f8ff 0%, #e1f0fc 100%) !important;
  border-radius: 12px !important;
  padding: 2.5rem !important;
  box-shadow: 0 8px 20px rgba(156, 220, 248, 0.2) !important;
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 2rem !important;
  align-items: center !important; /* Center align items vertically */
}

.join-1-percent .tools-success-content {
  flex: 1 !important;
  min-width: 300px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important; /* Center content vertically */
}

.join-1-percent .tools-success-header {
  align-items: flex-start !important;
  margin-bottom: 1.5rem !important;
}

.join-1-percent .tools-success-header h3 {
  color: #333 !important;
  font-size: 1.8rem !important;
  margin: 0 !important;
  text-align: left !important;
  position: relative !important;
  padding-bottom: 0.75rem !important;
}

.join-1-percent .tools-success-header h3:after {
  content: "" !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 60px !important;
  height: 3px !important;
  background-color: #9CDCF8 !important;
  border-radius: 2px !important;
}

.join-1-percent .tools-list {
  list-style-type: none !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 16px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.join-1-percent .tools-list li {
  display: flex !important;
  align-items: flex-start !important; /* Changed to flex-start to align items at the top */
  background-color: rgba(255, 255, 255, 0.85) !important;
  padding: 1.2rem 1.5rem !important;
  border-radius: 10px !important;
  transition: all 0.3s ease !important;
  border-left: 4px solid #9CDCF8 !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05) !important;
  text-align: left !important; /* Explicitly set text alignment */
}

.join-1-percent .tools-list li:last-child {
  margin-bottom: 0 !important;
}

.join-1-percent .tools-list li:hover {
  transform: translateX(5px) !important;
  background-color: rgba(255, 255, 255, 0.95) !important;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.08) !important;
}

.join-1-percent .tool-icon-container {
  background-color: #9CDCF8 !important;
  color: white !important;
  width: 42px !important;
  height: 42px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 1.2rem !important;
  margin-top: 0.2rem !important; /* Added to better align with text */
  flex-shrink: 0 !important;
  box-shadow: 0 4px 8px rgba(156, 220, 248, 0.3) !important;
}

.join-1-percent .tool-icon {
  font-size: 1.1rem !important;
}

.join-1-percent .tools-list li span {
  color: #333 !important;
  font-size: 1.1rem !important;
  line-height: 1.5 !important;
  display: block !important;
  text-align: left !important;
}

.join-1-percent .tools-list li strong {
  display: block !important;
  margin-bottom: 0.4rem !important;
  color: #111 !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  text-align: left !important;
}

/* Team Image Styling */
.join-1-percent .team-image-container {
  flex: 1 !important;
  min-width: 300px !important;
  max-width: 500px !important;
  min-height: 450px !important; /* Increased minimum height */
  border-radius: 15px !important;
  overflow: hidden !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1) !important;
  position: relative !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

.join-1-percent .team-image-container:hover {
  transform: translateY(-5px) !important;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15) !important;
}

.join-1-percent .team-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  object-position: center !important; /* Center the image */
  position: absolute !important; /* Position absolutely to fill container */
  top: 0 !important;
  left: 0 !important;
  transition: transform 0.6s ease !important;
}

.join-1-percent .team-image-container:hover .team-image {
  transform: scale(1.02) !important;
}

/* Updated How This Helps You Section with Image */
.join-1-percent .join-1-percent-help-section {
  max-width: 1500px !important;
  margin: 4rem auto 6rem !important; /* Increased bottom margin for the bottom line */
  position: relative !important;
  overflow: visible !important; /* Changed to visible to allow content to overflow */
}

.join-1-percent .help-section-inner {
  background-color: #9CDCF8 !important;
  border-radius: 15px !important;
  padding: 3.5rem !important;
  color: black !important;
  box-shadow: 0 15px 35px rgba(156, 220, 248, 0.3) !important;
  position: relative !important;
  z-index: 1 !important;
  margin-bottom: 3rem !important; /* Added space for the bottom line */
}

.join-1-percent .join-1-percent-help-section h2 {
  color: black !important;
  font-size: 3rem !important;
  text-align: left !important;
  margin-bottom: 1rem !important;
  font-weight: 800 !important;
  position: relative !important;
  display: inline-block !important;
  padding-bottom: 0.5rem !important;
}



/* New flexbox layout for content */
.join-1-percent .help-section-content {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 2.5rem !important;
  align-items: center !important;
}

/* Image container on the left - increased height to match right side */
.join-1-percent .help-image-container {
  flex: 1 !important;
  min-width: 300px !important;
  max-width: 450px !important;
  min-height: 600px !important; /* Increased height to match content height */
  border-radius: 15px !important;
  overflow: hidden !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15) !important;
  position: relative !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

.join-1-percent .help-image-container:hover {
  transform: translateY(-5px) !important;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.25) !important;
}

.join-1-percent .help-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  object-position: center !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  transition: transform 0.6s ease !important;
}

.join-1-percent .help-image-container:hover .help-image {
  transform: scale(1.02) !important;
}

/* Benefits content on the right */
.join-1-percent .help-benefits-container {
  flex: 1.5 !important;
  min-width: 300px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.join-1-percent .help-benefits-list {
  list-style-type: none !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 1.5rem !important;
}

.join-1-percent .benefit-item {
  display: flex !important;
  align-items: flex-start !important;
  background-color: rgba(255, 255, 255, 0.25) !important;
  padding: 1.5rem !important;
  border-radius: 10px !important;
  transition: transform 0.3s ease, background-color 0.3s ease !important;
  text-align: left !important;
  margin: 0 !important; /* Reset margin to use gap instead */
  width: auto !important; /* Reset width */
  border-left: 4px solid white !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08) !important;
}

.join-1-percent .benefit-item:hover {
  transform: translateY(-5px) !important;
  background-color: rgba(255, 255, 255, 0.35) !important;
}

.join-1-percent .benefit-icon-container {
  background-color: black !important;
  color: #9CDCF8 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 1.2rem !important;
  margin-top: 0.2rem !important;
  flex-shrink: 0 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.join-1-percent .benefit-icon {
  font-size: 1.2rem !important;
}

.join-1-percent .benefit-content {
  flex: 1 !important;
  font-size: 1.2rem !important;
  line-height: 1.5 !important;
  color: black !important;
  text-align: left !important;
}

.join-1-percent .benefit-content strong {
  color: black !important;
  font-weight: 600 !important;
  display: block !important;
  margin-bottom: 0.5rem !important;
  font-size: 1.3rem !important;
  text-align: left !important;
}

/* Bottom line moved outside the blue box */
.join-1-percent .bottom-line-container {
  text-align: center !important;
  padding: 2rem 3rem !important;
  background-color: white !important;
  border-radius: 15px !important;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1) !important;
  position: relative !important;
  max-width: 900px !important;
  margin: -1.5rem auto 0 !important; /* Negative top margin to overlap with blue box */
  z-index: 2 !important;
  border-top: 5px solid #9CDCF8 !important;
}

.join-1-percent .bottom-line {
  font-size: 2rem !important;
  font-weight: 700 !important;
  color: #333 !important;
  margin: 0 !important;
  position: relative !important;
  display: inline-block !important;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .join-1-percent .tools-success-container {
    padding: 2rem !important;
    flex-direction: column !important;
  }
  
  .join-1-percent .team-image-container {
    max-width: 100% !important;
    margin-top: 2rem !important;
    min-height: 400px !important; /* Adjusted for mobile */
  }
  
  .join-1-percent .help-section-inner {
    padding: 2.5rem !important;
  }
  
  .join-1-percent .help-section-content {
    flex-direction: column !important;
  }
  
  .join-1-percent .help-image-container {
    max-width: 100% !important;
    min-height: 500px !important; /* Adjusted for mobile but still substantial */
    margin-bottom: 1rem !important;
  }
  
  .join-1-percent .join-1-percent-help-section h2 {
    font-size: 2.5rem !important;
  }
  
  .join-1-percent .bottom-line-container {
    padding: 1.5rem 2rem !important;
  }
  
  .join-1-percent .bottom-line {
    font-size: 1.8rem !important;
  }
}

@media (max-width: 768px) {
  .join-1-percent .team-image-container {
    margin: 2rem auto 1rem !important;
    min-height: 350px !important;
  }
  
  .join-1-percent .tools-success-header h3 {
    text-align: center !important;
  }
  
  .join-1-percent .tools-success-header h3:after {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  
  .join-1-percent .join-1-percent-help-section h2 {
    font-size: 2.2rem !important;
  }
  
  .join-1-percent .help-image-container {
    min-height: 400px !important;
  }
  
  .join-1-percent .benefit-item {
    padding: 1.2rem !important;
  }
  
  .join-1-percent .benefit-content {
    font-size: 1.1rem !important;
  }
  
  .join-1-percent .benefit-content strong {
    font-size: 1.2rem !important;
  }
  
  .join-1-percent .bottom-line {
    font-size: 1.6rem !important;
  }
}

@media (max-width: 480px) {
  .join-1-percent .team-image-container {
    min-height: 300px !important;
  }
  
  .join-1-percent .help-section-inner {
    padding: 1.5rem !important;
  }
  
  .join-1-percent .join-1-percent-help-section h2 {
    font-size: 2rem !important;
  }
  
  .join-1-percent .help-image-container {
    min-height: 350px !important;
  }
  
  .join-1-percent .benefit-item {
    padding: 1rem !important;
  }
  
  .join-1-percent .benefit-content {
    font-size: 1rem !important;
  }
  
  .join-1-percent .benefit-content strong {
    font-size: 1.1rem !important;
  }
  
  .join-1-percent .bottom-line {
    font-size: 1.4rem !important;
  }
  
  .join-1-percent .bottom-line-container {
    padding: 1.2rem 1.5rem !important;
    margin-top: -1rem !important;
  }
}
